<script setup>
import {computed} from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
    additionalClasses: {
        type: String,
        default: '',
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
</script>


<template>
    <input v-model="proxyChecked"
           :value="value"
           :class="additionalClasses"
           class="form-checkbox is-basic h-5 w-5 rounded border-dark-text/40 dark:border-accent-dark/40 checked:border-primary-light checked:bg-primary-light hover:border-primary-light focus:border-primary-light dark:checked:border-accent-dark dark:checked:bg-accent-dark dark:hover:border-accent-dark dark:focus:border-accent-dark"
           type="checkbox"/>
</template>
